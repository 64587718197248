<template>
  <view-container breadcrumbs class="white py-0 px-0" fluid>
    <v-card flat>
      <toolbar :title="$t('srs.incidents')">
        <v-btn icon color="primary" @click="toggleFilter = !toggleFilter">
          <v-icon v-if="!toggleFilter">mdi-filter-menu</v-icon>
          <v-icon v-if="toggleFilter">mdi-filter-off-outline</v-icon>
        </v-btn>

        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn
          text
          x-small
          color="secondary"
          :disabled="!selected"
          @click="goToMap"
        >
          <v-icon>mdi-map-marker-right</v-icon>
          <span class="text--secondary">{{ $t('srs.map') }}</span>
        </v-btn>
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn
          text
          x-small
          color="green"
          @click="newAction"
        >
          <v-icon>mdi-folder-plus</v-icon>
          <span class="text--secondary">{{ $t('general.new') }}</span>
        </v-btn>
        <v-btn
          text
          x-small
          color="indigo darken-4"
          @click="newRFAction"
        >
          <v-icon>mdi-folder-plus</v-icon>
          <span class="text--secondary">{{ $t('general.new') }} RF</span>
        </v-btn>
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn
          text
          x-small
          color="primary"
          :disabled="!selected"
          @click="editAction()"
        >
          <v-icon>mdi-file-edit-outline</v-icon>
          <span>{{ $t('general.edit') }}</span>
        </v-btn>
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn
          text
          x-small
          color="red"
          v-if="!this.$hasRole('Administrator')"
          :disabled="!selected"
          @click="showDeleteRequest(selected)"
        >
          <v-icon>mdi-delete</v-icon>
          <span>{{ $t('general.deleteRequest') }}</span>
        </v-btn>
        <v-btn
          text
          x-small
          color="red"
          v-if="$hasRole('Administrator')"
          :disabled="isReadOnly || !selected"
          @click="deleteAction()"
        >
          <v-icon>mdi-delete</v-icon>
          <span>{{ $t('general.delete') }}</span>
        </v-btn>
      </toolbar>

      <incidents-filter
        v-model="filter"
        @clear="clearFilter"
        @filter="loadItems"
        v-show="toggleFilter"
      />

      <v-card-text
        class="overflow-y-auto px-0 py-0"
        :style="viewHeight(toggleFilter ? 172 : 123)"
      >
        <v-data-table
          dense
          :headers="getHeaders"
          :items="getIncidents"
          item-key="id"
          show-select
          single-select
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
          single-expand
          show-expand
          :expanded.sync="expanded"
          :page.sync="page"
          :items-per-page="pageSize"
          hide-default-footer
          @item-selected="selectionChanged"
          :server-items-length="getIncidentsTotal"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @update:sort-by="updateSort"
          @update:sort-desc="updateSort"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <div class="d-flex">
              <v-simple-checkbox
                on-icon="mdi-checkbox-marked-circle"
                off-icon="mdi-power-off"
                :value="isSelected"
                :color="item.deleteRequest ? 'red' : 'blue'"
                light
                @input="select($event)"
                :ripple="false"
                class="mx-0"
              ></v-simple-checkbox>
            </div>
          </template>
          <template #item.gcaNgcaName="{ item }">
            <v-chip
              v-if="item.gcaNgca"
              small
              label
              class="px-1"
              text-color="white"
              :color="item.gcaNgca === 17 ? 'red' : 'primary'"
              x-small
              v-text="item.gcaNgcaName" />
          </template>
          <template #item.dateTimeOfIncident="{ item }">
            {{  item.dateTimeOfIncident?.replace("T", " ") || "-"}}
          </template>
          <!-- <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              {{ item.deleteRequest }}
            </td>
          </template> -->
        </v-data-table>

        <div class="text-center px-2 d-flex justify-space-between pt-2" style="border-top: 1px dashed #CCC;">
          <div class="ma-3">{{ getTotal }} Reports</div>
          <v-pagination
            v-model="page"
            total-visible="11"
            :length="getIncidentsTotal > 0 ? Math.ceil(getIncidentsTotal / pageSize) : 0"
            @input="loadItems"
          ></v-pagination>
          <v-select
            :value="pageSize"
            :items="[100, 500, 1000]"
            label="Reports per page"
            @input="pageSize = parseInt($event)"
            style="font-size: 10pt; max-width: 200px;"
            solo
            dense
          ></v-select>
        </div>
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";
import IncidentsFilter from "./IncidentsFilter";
// import IncidentVerifyDialog from "./IncidentVerifyDialog";
import DeleteRequestDialog from "@/views/components/DeleteRequestDialog";
// import moment from "moment";

export default {
  name: "Incidents",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    ViewContainer,
    Toolbar,
    IncidentsFilter,
    // eslint-disable-next-line vue/no-unused-components
    DeleteRequestDialog,
  },
  data() {
    return {
      page: 1,
      pageSize: 100,
      sortBy: "id",
      sortDesc: true,
      search: "",
      toggleFilter: true,
      multiSelection: [],
      loading: false,
      expanded: [],
      filter: {
        search: null,
      },
      filterReset: {
        search: null,
      },
    };
  },
  mounted() {
    this.loading = true;
    this.page = parseInt(this.$route.query.page || 1);
    this.pageSize = parseInt(this.$route.query.size) || this.pageSize;

    const sort = this.$route.query.sort || "id,desc";
    this.sortBy = sort?.split(",")[0] || "id";
    this.sortDesc = sort?.split(",")[1] !== "asc";

    this.loadItems();
  },
  computed: {
    ...mapGetters("incidents", [
    "getIncidents",
    "getIncidentsTotal",
    "getSelectedIncident"]),
    
    getHeaders() {
      return [
        { text: this.$t("general.id"), value: "id", width: 60, align: "center", sortable: true, sortBy: "id" },
        { text: this.$t("si.narrativeWereReported"), value: "narrativeWereReported", sortable: false },
        { text: this.$t("si.gcaNgca"), value: "gcaNgcaName", sortable: true, sortBy: "gca_ngca_name" },
        { text: this.$t("si.area"), value: "areaName", sortable: true, sortBy: "area" },
        { text: this.$t("si.oblast"), value: "oblastName", sortable: true, sortBy: "oblast" },
        { text: this.$t("si.settlement"), value: "settlementName", sortable: false },
        { text: this.$t("si.incidentType"), value: "incidentTypeName", sortable: true, sortBy: "incident_type"},
        { text: this.$t("srs.dateTime"), value: "dateTimeOfIncident", sortable: true, sortBy: "date_time_of_incident" },
      ]
    },

    selected() {
      return this.getSelectedIncident;
    },
    selection() {
      return !this.getSelectedIncident ? [] : [this.getSelectedIncident];
    },
    getTotal() {
      const total = this.getIncidentsTotal;
      return total ? total.toLocaleString("en") : 0;
    },
  },
  methods: {
    ...mapActions("incidents", [
      "loadIncidentsByParams",
      "loadPrivateIncidentsByParams",
      "deleteIncident",
      "deleteIncidentRequest",
      "setSelectedIncident",
      "clearSelectedIncident",
    ]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    goToMap() {
      this.$router.push({
        name: "map",
      });
    },

    loadItems() {
      this.loading = true;
      // const isAdmin = this.$hasRole("Administrator");

      let params = {
        ...this.filter,
        page: this.page - 1,
        size: this.pageSize,
      };

      let sort = null;
      const sortBy = this.getHeaders.find((f) => f.value === this.sortBy)?.sortBy;
      if (sortBy) {
        sort = sortBy + (this.sortDesc ? ",desc" : ",asc");
        params = { ...params, sort };
      }

      // if (isAdmin) {
        this.loadIncidentsByParams(params)
          .then(() => {
            this.loading = false;
            this.updateQuery(params);
          })
          .catch(() => (this.loading = false));
      // } else {
      //   const id = this.$getUserInfo().organizationId;
      //   params = { ...params, organizationId: id };
      //   this.loadPrivateIncidentsByParams(params)
      //     .then(() => {
      //       this.loading = false;
      //       this.updateQuery(params);
      //     })
      //     .catch(() => (this.loading = false));
      // }
    },

    currentItemsChanged(e) {
      this.itemsCount = e.length;
    },

    selectionChanged(e) {
      if (e.value) {
        this.setSelectedIncident(e.item);
      } else {
        this.clearSelectedIncident();
      }
    },
    newAction() {
      this.$router.push({
        name: "incident",
        params: { incidentId: "new" },
      });
    },
    newRFAction() {
      this.$router.push({
        name: "incident-rf",
        params: { incidentId: "new" },
      });
    },
    editAction() {
      if (this.selected) {
        const r =  this.selected.gcaNgca === 17 ? "incident-rf" : "incident";
        this.$router.push({
          name: r,
          params: { incidentId: this.selected.id },
        });
      }
    },
    showDeleteRequest(item) {
      const dialog = {
        title: this.$t("general.deleteRequest"),
        component: DeleteRequestDialog,
        params: {
          item: { id: item.id, request: item.deleteRequest },
          editMode: true,
          undo: !!item.deleteRequest,
        },
        width: 450,
        actions: [
          {
            text: this.$t("general.okay"),
            validate: true,
            click: ({ item, undo, valid }) => {
              if (!valid) {
                return;
              }

              if (undo) {
                item.request = null;
              }

              this.deleteIncidentRequest(item).then(
                () => (this.selected.deleteRequest = item.request)
              );
              this.closeDialog();
            },
            color: "primary",
          },
          {
            text: "general.cancel",
            click: () => this.closeDialog(),
            color: "primary",
          },
        ],
      };
      this.showDialog(dialog);
    },
    deleteAction() {
      if (this.selected) {
        // this.deleteDialog = true;
        const confirmMessageKey = "organization.confirmDelte";
        const dialog = {
          title: "Delete",
          text: this.$t(confirmMessageKey),
          actions: [
            {
              text: "general.ok",
              click: () => {
                this.deleteIncident(this.selected.id);
                this.closeDialog();
              },
              color: "red",
            },
            {
              text: "general.no",
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };

        this.showDialog(dialog);
      }
    },
    clearFilter() {
      this.filter = { ...this.filterReset };
    },

    cutText(text) {
      return text && text.length > 100 ? text.substr(0, 100) : text;
    },

    updateQuery({ page, size, sort }) {
      if (
        !this.compareObjects(this.$route.query, { page: page + 1, size, sort })
      ) {
        this.$router.replace({
          query: { ...this.$route.query, page: page + 1, size, sort },
        });
      }
    },

    updateSort() {
      if(this.sortBy === undefined || this.sortDesc === undefined) {
        this.sortBy = "id";
        this.sortDesc = true;
      } else {
        this.loadItems();
      }
    },

  },
};
</script>
