<template>
  <div>
    <v-toolbar flat dense color="grey" class="lighten-5 toolbar" style="border-bottom: 1px dashed #9ec8d7 !important;">
      <v-btn icon @click="$router.go(-1)" v-if="backButton">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-divider
        vertical
        inset
        class="d-none d-md-flex mx-2"
        v-if="backButton"
      />
      <v-toolbar-title v-if="title" class="py-3 px-0" width="200">
        <span v-if="editMode && editor">
          {{ $t("general.edit") + ": " + (title ? title : "") }}
        </span>
        <span v-if="!editMode && editor">
          {{ $t("general.new") + ": " + (title ? title : "") }}
        </span>
        <span v-if="!editor">
          {{ title }}
        </span>
        <v-icon v-show="editMode && changed" color="primary">
          mdi-textbox-password
        </v-icon>
      </v-toolbar-title>
      <v-spacer v-if="title" />
      <v-chip class="px-2" small label v-if="isReadOnly" color="yellow">
        <v-avatar left>
          <v-icon small>mdi-alert</v-icon>
        </v-avatar>
        {{ $t("general.readonly") }}
      </v-chip>
      <v-divider v-if="!editMode && showKeepOpen" vertical inset class="mx-2" />
      <v-btn
        v-if="!editMode && showKeepOpen"
        small
        text
        @click="keepOpen = !keepOpen"
      >
        <v-icon v-show="keepOpen" color="primary">mdi-pin</v-icon>
        <v-icon v-show="!keepOpen" small>mdi-pin-outline</v-icon>
        <span class="text--secondary">{{ $t("general.keepopen") }}</span>
      </v-btn>
      <!-- <v-divider v-if="!editMode" vertical inset class="mx-2" /> -->
      <slot></slot>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: "Toolbar",
  data() {
    return {
      keepOpen: false,
    };
  },
  props: {
    title: String,
    editor: Boolean,
    editMode: Boolean,
    backButton: Boolean,
    readOnly: Boolean,
    changed: Boolean,
    showKeepOpen: Boolean,
  },
};
</script>

<style>
.toolbar span,
.toolbar label {
  font-size: 8pt;
  text-transform: uppercase;
}

.v-toolbar__title span {
  font-size: 14pt;
  text-transform: none;
}
</style>
