<template>
  <v-container>
    <v-form ref="form" v-model="params.valid">
      <v-textarea
        v-model="params.item.request"
        :label="$t('general.message')"
        background-color="grey lighten-4"
        :rules="rules.deleteRequest"
        dense
        rows="3"
        :disabled="!!params.undo"
      ></v-textarea>
      <v-checkbox
        v-if="params.editMode"
        v-model="params.undo"
        :label="$t('general.undo')"
        dense
      ></v-checkbox>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "DeleteRequestDialog",
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      rules: {
        deleteRequest: [
          (v) =>
            this.params.undo || !!v || "Delete request message is required unless you want to undo request.",
        ],
      },
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
