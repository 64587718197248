<template>
  <!-- :max-width="width || '290px'"-->
  <div class="date-selector">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      :disabled="readOnly"
      :min-width="width || '290px'"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDate"
          :label="label"
          readonly
          clearable
          @click:clear="() => (date = null)"
          persistent-hint
          :append-icon="hideIcon ? '' : 'mdi-calendar'"
          v-bind="attrs"
          v-on="on"
          :dense="dense"
          :rules="rules"
          validate-on-blur
        ></v-text-field>
      </template>
      <!-- :style="`max-width: ${width}px;`" -->
      <!-- :rules="[ v => v || 'Event time is required']" -->
      <!-- v-model="value" -->
      <v-date-picker
        v-model="date"
        no-title
        @input="menu = false"
        :readonly="readOnly"
        @change="(v) => $emit('change', v)"
        validate-on-blur
        :style="`max-width: 300px;`"
        :type="month ? 'month' : undefined"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DateSelector",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    readOnly: Boolean,
    value: String,
    rules: Array,
    hideIcon: Boolean,
    dense: Boolean,
    month: Boolean,
    label: String,
    width: {
      type: Number,
      default() {
        return 90;
      },
    },
  },
  data() {
    return {
      menu: false,
      date: this.value,
    };
  },
  watch: {
    date(v) {
      this.$emit("input", v);
    },
    value(v) {
      this.date = v;
    },
  },
  computed: {
    computedDate: {
      get() {
        return this.getDateValue(this.date);
      },
      set() {
        this.$emit("input", this.date);
      },
    },
  },
  methods: {
    getDateValue(v) {
      if (v && moment(v).isValid()) {
        return moment(v).format("MM/DD/YYYY");
      }
      return null;
    },
  },
};
</script>
