<template>
  <div>
    <v-divider />
    <v-toolbar flat short dense class="grey lighten-5 sa-filter">
        <v-select
          v-model="filter.incidentType"
          :items="getTypesByGroup('SRS_INCIDENT_TYPE')"
          item-value="id"
          item-text="name"
          dense
          :label="$t('si.incidentType')"
          style="height: 29px; max-width: 100px"
          class="mr-1"
        ></v-select>
        <v-divider  vertical inset class="d-none d-md-flex mx-2" />
        <v-select
          v-model="filter.area"
          :items="getTypesByGroup('SRS_AREA')"
          item-value="id"
          item-text="name"
          dense
          :label="$t('si.area')"
          style="height: 29px; max-width: 100px"
          class="mr-1"
        ></v-select>
        <v-divider  vertical inset class="d-none d-md-flex mx-2" />
        <v-select
          v-model="filter.gcaNgca"
          :items="getTypesByGroup('SRS_GCA_NGCA')"
          item-value="id"
          item-text="name"
          dense
          :label="$t('si.gcaNgca')"
          style="height: 29px; max-width: 100px"
          class="mr-1"
        ></v-select>
        <v-divider  vertical inset class="d-none d-md-flex mx-2" />
        <date-selector
          v-model="filter.from"
          hide-icon
          :label="$t('general.from')"
          width="200"
        />
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <date-selector
          v-model="filter.to"
          hide-icon
          :label="$t('general.to')"
          width="200"
        />
      <v-spacer />
      <v-text-field
        class="d-none d-md-flex"
        v-model="filter.search"
        append-icon="mdi-magnify"
        :label="$t('general.search')"
        single-line
        hide-details
        dense
        clearable
        style="width: 0"
      ></v-text-field>
      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-btn
        depressed
        x-small
        color="green"
        class="mx-2"
        dark
        @click="$emit('filter')"
        v-if="!auto"
      >
        <v-icon small> mdi-magnify </v-icon>
        {{ $t('general.search') }}
      </v-btn>
      <v-btn depressed x-small color="primary" dark @click="clear">
        <v-icon small> mdi-magnify-close </v-icon>
        {{ $t('general.clear') }}
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DateSelector from "@/common/components/DateSelector";

export default {
  name: "IncidentsFilter",
  // eslint-disable-next-line vue/no-unused-components
  components: { DateSelector },
  model: {
    prop: "filter",
    event: "input",
  },
  props: {
    auto: Boolean,
    filter: {
      type: Object,
      default() {
        return {
          search: null,
          area: "ALL",
          gcaNgca: "ALL",
          incidentType: "ALL",
        };
      },
    },
  },
  mounted() {
    this.loadTypes();
  },
  computed: {
    ...mapGetters("types", ["getTypesByGroup"]),
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),

    clear() {
      this.$emit("clear");
      this.$emit("filter");
    },
  },
};
</script>
